import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { ImFilePdf, ImImage, ImFileWord, ImFileExcel } from 'react-icons/im';
import axios from "axios";
import { FadeLoader } from "react-spinners"; 

const Novosti = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;

  const [selectedYear, setSelectedYear] = useState("Sve");
  const [selectedCategory, setSelectedCategory] = useState("Novosti"); // Početno odabrana kategorija je "Novosti"

  useEffect(() => {
    async function fetchData() {
      let allPosts = [];
      let totalPages = 1;
      let currentPage = 1;

      while (currentPage <= totalPages) {
        const response = await axios.get(
          `https://asu.forward.ba/wp-json/wp/v2/posts?categories=1&_embed&?_fields=acf&acf_format=standard&per_page=${recordsPerPage}&page=${currentPage}`
        );

        const responseData = response.data;
        allPosts = allPosts.concat(responseData);
        totalPages = response.headers["x-wp-totalpages"];
        currentPage++;
      }

      allPosts.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
      });

      setData(allPosts);
      setFilter(allPosts);
      setLoading(false);
    }

    fetchData();
  }, []);

  const nPages = Math.ceil(filter.length / recordsPerPage);

  const requestSearch = (searchedVal) => {
    const filteredRows = data.filter((row) => {
      return row.title.rendered
        .toString()
        .toLowerCase()
        .includes(searchedVal.toString().toLowerCase());
    });
    if (searchedVal.length < 1) {
      setFilter(data);
    } else {
      setFilter(filteredRows);
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filter.slice(indexOfFirstRecord, indexOfLastRecord);

  const handleYearFilterChange = (event) => {
    const selectedYear = event.target.value;
    setSelectedYear(selectedYear);

    if (selectedYear === "Sve") {
      setFilter(data);
    } else {
      const filteredPosts = data.filter((post) => {
        const postYear = new Date(post.date).getFullYear().toString();
        return postYear === selectedYear;
      });
      setFilter(filteredPosts);
    }
  };

  const handleCategoryFilterChange = (category) => {
    setSelectedCategory(category);

    if (category === "Novosti") {
      setFilter(data);
    } else {
      const filteredPosts = data.filter((post) => {
        return post.categories.includes(category.toLowerCase());
      });
      setFilter(filteredPosts);
    }
  };

  const availableYears = [
    ...new Set(data.map((post) => new Date(post.date).getFullYear().toString())),
  ];

  const availableCategories = ["Novosti", "Ostale novosti", "Javne nabavke", "Konkursi"];

  return (
    <div className="container drugaSekcija dekanat">
      <div className="container dekanat">
        <div className="container drugaSekcija" style={{backgroundColor:"white"}}>
          <h1>Novosti</h1>
          <div className="d-flex align-items-center mb-3">
            <div>
              <TextField
                onChange={(e) => requestSearch(e.target.value)}
                placeholder="Traži novost"
              />
            </div>
            <div>
              <Select
                value={selectedYear}
                onChange={handleYearFilterChange}
                displayEmpty
              >
                <MenuItem value="Sve">Sve godine</MenuItem>
                {availableYears.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </div>
            {/* <div>
              {availableCategories.map((category) => (
                <Button
                  key={category}
                  variant={selectedCategory === category ? "contained" : "outlined"}
                  onClick={() => handleCategoryFilterChange(category)}
                >
                  {category}
                </Button>
              ))}
            </div> */}
          </div>
          {loading ? (
            <div
              className="text-center"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
              }}
            >
              <FadeLoader color={"#123abc"} loading={loading} size={100} />
            </div>
          ) : (
            <div className="d-flex bd-highlight">
              <div style={{ background: 'white' }}>
  {availableCategories.map((category) => (
    <Button
      key={category}
      variant={selectedCategory === category ? "contained" : "outlined"}
      onClick={() => handleCategoryFilterChange(category)}
      // style={{ color: 'black', display: 'block', margin: '5px 0',width: "100%" }}
      style={{
        backgroundColor: selectedCategory === category ? "black" : "white",
        color: selectedCategory === category ? "white" : "black",
        display: 'block', marginBottom: "5px",width: "100%", border: "1px solid black", 
      }}
    >
      {category}
    </Button>
  ))}
</div>

              <div className="p-2 flex-fill bd-highlight">
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Naslov</TableCell>
                        <TableCell>Datum objave</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentRecords.map((row, index) => (
                        <TableRow key={row.id}>
                          <TableCell>
                            <Link to={`/novosti/${row.id}`} class="card-link">
                              <img 
                                // src={row?._embedded['wp:featuredmedia'][0]?.media_details?.sizes?.medium?.source_url || ""}
                                src={row?._embedded['wp:featuredmedia']?.[0]?.media_details?.sizes?.medium?.source_url || ""}
                                alt="" 
                                height="45px"
                              />
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Link to={`/novosti/${row.id}`} class="card-link">
                              {row.title.rendered}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {new Date(row?.date).toLocaleDateString()}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  count={nPages}
                  page={currentPage}
                  onChange={handleChangePage}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Novosti;
