import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useParams } from 'react-router-dom';
import { ImFilePdf, ImImage, ImFileWord, ImFileExcel } from 'react-icons/im';
import { FadeLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const NastavnikDetalj = () => {
    const { id } = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('selectedLanguage');
    useEffect(() => {
        Axios.get(`https://asu.forward.ba/wp-json/wp/v2/pages/${id}?_embed&?_fields=acf&acf_format=standard`)
            .then((res) => {
                setData(res.data);
                setLoading(false);
            })
            .catch(() => {
                console.log('There was an error while retrieving the data');
            });
    }, [id]);

    const naslov = storedLanguage === 'en' ? data?.acf?.prevod_nasova : data?.title?.rendered || "";
    const opis = storedLanguage === 'en' ? data?.acf?.prevod_teksta : data?.content?.rendered || "";
 // Translate days of the week if needed
 const translateDay = (day) => {
  if (storedLanguage === 'en') {
      switch (day) {
          case 'Ponedjeljak': return 'Monday';
          case 'Utorak': return 'Tuesday';
          case 'Srijeda': return 'Wednesday';
          case 'Četvrtak': return 'Thursday';
          case 'Petak': return 'Friday';
          case 'Subota': return 'Saturday';
          case 'Nedjelja': return 'Sunday';
          default: return day;
      }
  }
  return day;
};
    const prikaziOpis = {
        replace: (domNode) => {
            if (domNode?.name === 'a' && domNode?.attribs?.href && domNode?.attribs?.href.endsWith('.pdf')) {
                return (
                    <a href={domNode?.attribs?.href} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '5px' }} />
                        {domNode?.children[0]?.data || "PDF File"}
                    </a>
                );
            }
            return null;
        },
    };


    return (
        <>
            <div className="container drugaSekcija dekanat nastavnik">
                <div className="container dekanat">
                    {loading ? (
                        <div
                            className="text-center"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '70vh', // Postavite visinu na visinu ekrana (100% viewport visine)
                            }}
                        >
                            <FadeLoader color={"#123abc"} loading={loading} size={100} />
                        </div>
                    ) : (
                        <main role="main" className="container drugaSekcija">
                            <div className="row">
                                <div className="col-md-4">
                                    {data && data._embedded && data._embedded['wp:featuredmedia'] !== undefined ? (
                                        <img
                                            className="card-img-top nastanikSlika"
                                            alt="example"
                                            src={data._embedded['wp:featuredmedia'][0]?.media_details?.sizes?.full?.source_url}
                                        />
                                    ) : " "}
                                </div>
                                <div className="col-md-8">
                                    {/* <div dangerouslySetInnerHTML={{ __html: data?.content?.rendered }}></div> */}
                                    {parse(opis, prikaziOpis)}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <h1 style={{ color: "white", fontWeight: 'normal', fontSize: "2rem" }}>
                                        {naslov}
                                    </h1>
                                    <p>{t('university')} - {t('academy')}</p>
                                    <p>{t('course')}: {data?.acf?.predmet}</p>
                                    <p>{t('email')}: {data?.acf?.email_adresa}</p>
                                </div>
                                <div className="col-md-4">
                                    <p>{t('consultationSchedule')}</p>
                                    {data?.acf?.konsultacije ? (
                                        data.acf.konsultacije.map((el, index) => (
                                            <p key={index}>{translateDay(el.dan)} {el.vrijeme}</p>
                                        ))
                                    ) : ""}
                                </div>
                                <div className="col-md-4">
                                    {/* <div className="p-2 bd-highlight"> <img src="./media/images/ikone.png" className="img-fluid" alt="..."/> </div> */}
                                </div>
                                <hr />
                            </div>
                        </main>
                    )}
                </div>
            </div>
        </>
    );
};

export default NastavnikDetalj;
