import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { FadeLoader } from "react-spinners"; 
import { useTranslation } from 'react-i18next';
const Vanredni = () => {
  const [profesori, setProfesori] = useState([]);
  const [loading, setLoading] = useState(true);
  const storedLanguage = localStorage.getItem('selectedLanguage');
  const { t, i18n } = useTranslation();
  useEffect(() => {
    async function dohvatiSveStranice() {
      try {
        let stranice = [];
        let stranica = 1;
        let ukupnoStranica = Infinity;

        while (stranica <= ukupnoStranica) {
          const response = await axios.get(`https://asu.forward.ba/wp-json/wp/v2/pages?categories=14&_embed&page=${stranica}`);

          if (response.data.length === 0) {
            break;
          }

          stranice = stranice.concat(response.data);
          ukupnoStranica = response.headers['x-wp-totalpages'];

          stranica++;
        }

        // Sortiraj profesore prema prezimenu
        const sortedProfesori = stranice.sort((a, b) => {
          const prezimeA = a.title.rendered.split(" ").pop();
          const prezimeB = b.title.rendered.split(" ").pop();
          return prezimeA.localeCompare(prezimeB);
        });

        setProfesori(sortedProfesori);
        setLoading(false);
      } catch (error) {
        console.error('Greška prilikom dohvata stranica:', error);
      }
    }

    dohvatiSveStranice();
  }, []);

  return (
    <div className="container drugaSekcija dekanat">
      <div className="container dekanat">
        <div className="container drugaSekcija">
        <h4>{t('academicStaff').toUpperCase()} | {t('associateProfessors').toUpperCase()}</h4>
          {loading ? (
            <div
              className="text-center"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '70vh',
              }}
            >
              <FadeLoader color={"#123abc"} loading={loading} size={100} />
            </div>
          ) : (
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
              {profesori?.map((el) => {
                return (
                  <div className="col mb-4" key={el.id} style={{ width: "15%" }}>
                    <Link to={`/osoblje/${el.id}`} className="card-link">
                      <div className="card" style={{ backgroundColor: "unset", border: 0 }}>
                        {el && el._embedded && el._embedded['wp:featuredmedia'] !== undefined ? (
                          <img
                            className="card-img-top img-fluid img-thumbnail profe"
                            alt="example"
                            style={{ width: 150, height: 150, objectFit: 'cover', objectPosition: 'center 10%' }}
                            src={el._embedded['wp:featuredmedia'][0]?.media_details?.sizes?.medium?.source_url}
                          />
                        ) : (
                          ' '
                        )}
                        <div className="card-body" style={{ padding: 0, paddingLeft: 2 }}>
                          <p style={{ fontSize: 13 }}>
                            {el?.acf?.titula} {el?.title?.rendered}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Vanredni;
