import React, {useState, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom';
import Axios from 'axios'
import { FadeLoader } from "react-spinners"; 
import { ImFilePdf, ImImage, ImFileWord, ImFileExcel } from "react-icons/im";
import { Helmet } from 'react-helmet';
import './stilovi.css';
import GalleryFromHTML from '../galerija/GalleryFromHTML';
import Sadrzaj from './Sadrzaj';


const NovostDetalj = () =>{
    const {id} = useParams();
    const [novost, setNovost] = useState({});

    const [loading, setLoading] = useState(true);
    // console.log(novost)

    useEffect(()=>{
      // Axios.get(`https://asu.forward.ba/wp-json/wp/v2/posts/${id}?_fields=acf&acf_format=standard`)
      //   // Axios.get(`https://asu.forward.ba/wp-json/wp/v2/posts/${id}?_embed`)
      //   .then((res)=>{
      //       setNovost(res.data);
      //       setLoading(false);
      //   })

      Axios.get(`https://asu.forward.ba/wp-json/wp/v2/posts/${id}?_fields=acf,title,content&acf_format=standard`)
  .then((res) => {
    const postData = res.data;
    // console.log(postData); // Ovdje će biti podaci o postu, uključujući ACF polja i naslov posta.
    // const acfData = postData.acf;
    setNovost(postData);
    setLoading(false);
    // const title = postData.title.rendered;

    // Sada možete koristiti acfData i title kako vam odgovara.
  })
  .catch((error) => {
    console.error('Greška prilikom dohvata podataka o postu:', error);
  });
    },[])
    // console.log(novost)
    return(
        <>
        <Helmet>
        <title>{novost?.title?.rendered}</title>
        <meta property="og:title" content={novost?.title?.rendered} />
        <meta property="og:description" content={novost?.excerpt?.rendered} />
        {/* <meta property="og:image" content={novost?._embedded['wp:featuredmedia'][0]?.media_details?.sizes?.medium?.source_url} /> */}
        {/* Dodajte druge Open Graph meta tagove prema potrebi */}
      </Helmet>
   <div class="container drugaSekcija dekanat nastavnik">
 <div class="container dekanat">
 {loading ? (
                        <div
                        className="text-center"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '70vh', // Postavite visinu na visinu ekrana (100% viewport visine)
                        }}
                      >
                        <FadeLoader color={"#123abc"} loading={loading} size={100} />
                      </div>
                    ) : (
    <main role="main" class="container drugaSekcija">
            <div class="row">
            <h3>{novost?.title?.rendered}</h3>
                {/* <div dangerouslySetInnerHTML={{ __html: novost?.content?.rendered }} ></div> */}
                {/* <div id="stilizirani-div" dangerouslySetInnerHTML={{ __html: novost?.content?.rendered }}></div> */}
                <Sadrzaj htmlContent={novost?.content?.rendered}/>
                <div id="stilizirani-div">
  <GalleryFromHTML htmlContent={novost?.content?.rendered} />
  </div>
           {novost && novost._embedded && novost._embedded['wp:featuredmedia'] !== undefined ?
               <img class="img-fluid img-thumbnail"
                alt="example"
                src={novost && novost._embedded && novost?._embedded['wp:featuredmedia'][0]?.media_details?.sizes?.medium?.source_url}
                />
            : " "
            }
            <p>
            {novost?.acf?.dokumenti ? (
                              novost?.acf?.dokumenti.map((el) => {
                                return (
                                  <React.Fragment
                                    key={el?.naziv_dokumenta}
                                  >
                                    {(() => {
                                      if (el.dokument.subtype === "pdf") {
                                        return (
                                          <a href={el?.dokument?.url}>
                                            <ImFilePdf size="25" />
                                          </a>
                                        );
                                      } else if (
                                        el?.dokument?.subtype === "jpeg" ||
                                        el?.dokument?.subtype === "jpg" ||
                                        el?.dokument?.subtype === "png"
                                      ) {
                                        return (
                                          <a href={el?.dokument?.url}>
                                            <ImImage size="25" />
                                          </a>
                                        );
                                      } else if (
                                        el?.dokument?.subtype === "docx" ||
                                        el?.dokument?.subtype === "doc"
                                      ) {
                                        return (
                                          <a href={el?.dokument?.url}>
                                            <ImFileWord size="25" />
                                          </a>
                                        );
                                      } else if (
                                        el?.dokument?.subtype ===
                                        "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                      ) {
                                        return (
                                          <a href={el?.dokument?.url}>
                                            <ImFileExcel size="25" />
                                          </a>
                                        );
                                      } else {
                                        return <p></p>;
                                      }
                                    })()}
                                  </React.Fragment>
                                );
                              })
                            ) : (
                              ""
                            )}
            </p>
            </div>               
        </main>
            )}
        </div>
        </div>
        </>
    )
}

export default NovostDetalj;