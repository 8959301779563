// import logo from './logo.svg';
import React from 'react'; // Dodajte ovu liniju
import './App.css';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HashRouter as Router, Switch, Route , Redirect,Routes} from "react-router-dom";
import Odsjeci from './components/odsjeci';
import Home from './components/home';
import Footer from './components/home/footer';
import Header from './components/home/header';
import Nastavnici from './components/nastavnici';
import Dekanat from './components/dekanat';
import OdsijekDetalj from './components/odsjeci/OdsjekDetalj';
import NovostDetalj from './components/novosti/NovostDetalj';
import Novosti from './components/novosti';
import Redovni from './components/nastavnici/Redovni';
import OpstiAkti from './components/propisiAkti/OpstiAkti';
import Odluke from './components/propisiAkti/Odluke';
import JavneNabavke from './components/propisiAkti/JavneNabavke';
import IzvjestajOradu from './components/propisiAkti/IzvjestajOradu';
import JavnostRada from './components/propisiAkti/JavnostRada';
import TijelaAsu from './components/akademija/TijelaASU';
import NagradaRazijaLagumdzija from './components/akademija/NagradaRazijaLagumdzija';
import RadiliNaASU from './components/akademija/RadiliNaASu';
import InMemoriam from './components/akademija/InMemoriam';
import DekaniHistory from './components/akademija/DekaniKrozHistoriju';
import Kontakt from './components/akademija/Kontakt';
import PolitikaOsiguranjaKvalitetaAsu from './components/akademija/politikaOsiguranjaKvalitetaAsu';
import MisljenjaEtickogOdbora from './components/akademija/MisljenjaEtickogOdbora';
import Alumni from './components/akademija/Alumni';
import Docenti from './components/nastavnici/Docenti';
import Obala from './components/obala/Obala';
import NastavnikDetalj from './components/nastavnici/NastavnikDetalj';
import IzboriUZvanja from './components/nastavnici/IzboriUZvanja';
import Saradnici from './components/nastavnici/Saradnici';
import Vanredni from './components/nastavnici/Vanredni';
import Vass from './components/nastavnici/Vass';
import Ass from './components/nastavnici/Ass';
import OBiblioteci from './components/biblioteka/OBibLib';
import MrezniKatalog from './components/biblioteka/MrezniKatalog';
import BazePodataka from './components/biblioteka/BazePodataka';
import Izdavastvo from './components/biblioteka/Izdavastvo';
import ObavijestiBibLib from './components/biblioteka/Obavijesti';
import StudentiDrama from './components/studenti/StudentiDramaturgija';
import PageDetail from './components/home/PageDetail';
import International from './components/topmenu/International';
import NovaZgradaASU from './components/topmenu/NovaZgradaASU';
import StudentiGluma from './components/studenti/StudentiGluma';
import StudentiRezija from './components/studenti/StudentiRezija';
import StudentiProdukcija from './components/studenti/StudentiProdukcija';
import DiplomiraliASU from './components/studenti/DiplomiraliNaASU';
import NastavniProces from './components/studenti/NastavniProces';
import DiplomiraliDetalj from './components/studenti/DiplomiraliDetalj';
import Popup from './components/popup/Popup';

function App() {
  return (
    <>
    <Router>
        {/* <Navbar /> */}
        <Header/>
        <Popup/>
        {/* <Navbar />  */}
        {/* Add your Navbar component here */}
        {/* <main className=''> */}
        <Routes>
        
            <Route exact key="home" path='/' element={<Home />} />
            <Route path='/stranica/:id' element={<PageDetail />} />
            <Route path='/medjunarodna-saradnja' element={<International />} />
            <Route path='/nova-zgrada' element={<NovaZgradaASU />} />

            <Route key="odsjeci" path='/odsjeci' element={<Odsjeci />} />
            <Route path='/odsjeci/:id' element={<OdsijekDetalj />} />
            <Route path='/redovni' element={<Redovni />} />
            <Route path='/redovni/:id' element={<NastavnikDetalj />} />

            <Route path='/docenti' element={<Docenti />} />
            <Route path='/osoblje/:id' element={<NastavnikDetalj />} />

            <Route path='/vanredni' element={<Vanredni />} />
            <Route path='/vanredni/:id' element={<NastavnikDetalj />} />

            <Route path='/visi-ass' element={<Vass />} />
            <Route path='/visi-ass/:id' element={<NastavnikDetalj />} />

            <Route path='/ass' element={<Ass />} />
            <Route path='/ass/:id' element={<NastavnikDetalj />} />

            <Route path='/saradnici' element={<Saradnici />} />
            <Route path='/izbori-u-zvanja' element={<IzboriUZvanja />} />
            
            <Route exec path='/dekanat' element={<Dekanat />} />
            <Route path='/novosti/:id' element={<NovostDetalj />} />
            <Route exec path='/novosti' element={<Novosti />} />
            {/*Propisi iakti*/}
            
           
            <Route path='/opsti-akti' element={<OpstiAkti />} />
            <Route path='/odluke' element={<Odluke />} />
            <Route path='/javne-nabavke' element={<JavneNabavke />} />
            <Route path='/izvjestaj-o-radu' element={<IzvjestajOradu />} />
            <Route path='/javnost-rada' element={<JavnostRada />} />
            
            {/**O biblioteci */}
            <Route path='/o-biblioteci' element={<OBiblioteci />} />
            <Route path='/mrezni-katalog' element={<MrezniKatalog />} />
            <Route path='/baze-podataka' element={<BazePodataka />} />
            <Route path='/izdavastvo' element={<Izdavastvo />} />
            <Route path='/bilioteka-obavijesti' element={<ObavijestiBibLib />} />
          

            <Route path='/tijela-asu' element={<TijelaAsu />} />
            <Route path='/nagrada-razija-lagumdzija' element={<NagradaRazijaLagumdzija />} />
            <Route path='/radili-na-asu' element={<RadiliNaASU />} />
            <Route path='/in-memoriam' element={<InMemoriam />} />
            <Route path='/dekani-kroz-historiju' element={<DekaniHistory />} />
            <Route path='/politika-osiguranja-kvaliteta-asu' element={<PolitikaOsiguranjaKvalitetaAsu />} />
            <Route path='/misljenja-etickog-odbora' element={<MisljenjaEtickogOdbora />} />
            <Route path='/kontakt' element={<Kontakt />} />
            <Route path='/alumni' element={<Alumni />} />

            {/***Obala**/}
            <Route exact path='/obala' element={<Obala />} />

            {/*studenti*/}
            <Route path='/studenti-dramaturgija' element={<StudentiDrama />} />
            <Route path='/studenti/:id' element={<NastavnikDetalj />} />

            <Route path='/studenti-gluma' element={<StudentiGluma />} />
            <Route path='/studenti-rezija' element={<StudentiRezija />} />
            <Route path='/studenti-produkcija' element={<StudentiProdukcija />} />
            <Route path='/studenti-diplomirali' element={<DiplomiraliASU />} />
            <Route exact path='/studenti-diplomirali/:id' element={<DiplomiraliDetalj />} />
            <Route path='/studenti-nastavni-proces' element={<NastavniProces />} />
            
          </Routes>
        <Footer />
        {/* </main> */}
    </Router>
    </>
  );
}

export default App;
