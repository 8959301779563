import React, { useState } from 'react';
import Carousel from 'react-images';

const GalleryFromHTML = ({ htmlContent }) => {
  // Funkcija za izdvajanje URL-ova slika iz HTML-a
  const extractImageUrls = (html) => {
    const regex = /<img.*?src="(.*?)"/g;
    const urls = [];
    let match;
    while ((match = regex.exec(html)) !== null) {
      urls.push({ src: match[1] });
    }
    return urls;
  };


  const images = extractImageUrls(htmlContent);
  console.log(images.length)
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  return (
    <>
      {images.length > 1 ? (
        <div>
          <Carousel
            views={images}
            currentIndex={selectedImageIndex}
            onClickPrev={() => setSelectedImageIndex((selectedImageIndex - 1 + images.length) % images.length)}
            onClickNext={() => setSelectedImageIndex((selectedImageIndex + 1) % images.length)}
          />
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', overflowX: 'scroll' }}>
            {images.map((image, index) => (
              <img
                key={index}
                src={image.src}
                alt={`Thumbnail ${index}`}
                style={{ width: '100px', margin: '0 10px', cursor: 'pointer' }}
                onClick={() => handleImageClick(index)}
              />
            ))}
          </div>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={images[0]?.src}  style={{ maxWidth: '100%', height: 'auto' }} />
        </div>
      )}
    </>
  );
};

export default GalleryFromHTML;
