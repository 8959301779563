import React, { useState, useEffect } from "react";
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { FadeLoader } from "react-spinners"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';

const Obala = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('selectedLanguage');

    useEffect(() => {
        axios.get("https://asu.forward.ba/wp-json/wp/v2/pages/368")
            .then((res) => {
                console.log(res)
                setData(res.data);
                setLoading(false);
            })
            .catch(() => {
                alert('There was an error while retrieving the data')
            })
    }, [])

    const naslov = storedLanguage === 'en' ? data?.acf?.prevod_nasova : data?.title?.rendered || "";
    const opis = storedLanguage === 'en' ? data?.acf?.prevod_teksta : data?.content?.rendered || "";

    // Ova funkcija će zamijeniti linkove na PDF-ove sa prilagođenim prikazom
    const prikaziOpis = {
        replace: (domNode) => {
            if (domNode?.name === 'a' && domNode?.attribs?.href && domNode?.attribs?.href.endsWith('.pdf')) {
                return (
                    <a href={domNode?.attribs?.href} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '5px' }} />
                        {domNode?.children[0]?.data || "PDF File"}
                    </a>
                );
            }
            return null;
        },
    };

    return (
        <div className="container drugaSekcija dekanat">
            <div className="container dekanat">
                <h1>{storedLanguage === 'en' ? naslov : parse(naslov)}</h1>

                {/* Prikazuje spinner dok se podaci učitavaju */}
                {loading ? (
                    <div
                        className="text-center"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '70vh',
                        }}
                    >
                        <FadeLoader color={"#123abc"} loading={loading} size={100} />
                    </div>
                ) : (
                    <div className="container drugaSekcija">
                        <div className="d-flex bd-highlight">
                            <div className="p-2 flex-fill bd-highlight">
                                {/* Ovdje koristimo parse sa opcijom replace */}
                                {parse(opis, prikaziOpis)}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Obala;
