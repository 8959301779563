import React, { useState, useEffect } from "react";
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { RingLoader } from "react-spinners"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
// import FastfoodIcon from '@mui/icons-material/Fastfood';
// import LaptopMacIcon from '@mui/icons-material/LaptopMac';
// import HotelIcon from '@mui/icons-material/Hotel';
// import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';

const DekaniHistory = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('selectedLanguage');

    useEffect(() => {
        axios.get("https://asu.forward.ba/wp-json/wp/v2/pages/153")
            .then((res) => {
                console.log(res)
                setData(res.data);
                setLoading(false);
            })
            .catch(() => {
                alert('There was an error while retrieving the data')
            })
    }, [])

    // Proverite da li su podaci učitani pre nego što pristupite svojstvima
    
    // if (loading) {
    //     return <div>Loading...</div>;
    // }

    const naslov = storedLanguage === 'en' ? data?.acf?.prevod_nasova : data?.title?.rendered || "";
    const opis = storedLanguage === 'en' ? data?.acf?.prevod_teksta : data?.content?.rendered || "";

    const prikaziOpis = parse(opis, {
        replace: (domNode) => {
            if (domNode.name === 'a' && domNode.attribs.href && domNode.attribs.href.endsWith('.pdf')) {
                return (
                    <a href={domNode.attribs.href} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '5px' }} />
                        {domNode.children[0] && domNode.children[0].data}
                    </a>
                );
            }
        },
    });
    
    return (
        <div className="container drugaSekcija dekanat">
            <div className="container dekanat">
                 {/* Prikazuje spinner dok se podaci učitavaju */}
                 {/* <h1>{parse(naslov)}</h1> */}
                 {/* {loading ? (
                        <div className="text-center">
                            <RingLoader color={"#123abc"} loading={loading} style={{display:'block',margin: '0 auto' }} size={100} />
                        </div>
                    ) : (
                <div className="container drugaSekcija">
                
                    <div className="d-flex bd-highlight">
                        <div className="p-2 flex-fill bd-highlight">
                        {prikaziOpis}
                        </div>
                    </div>
                </div>
                  )} */}
                  <Timeline position="alternate">
                    
        <TimelineItem>
            <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
            >
            decembar 1981. - august 1986.
            </TimelineOppositeContent>
            <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
                {/* <FastfoodIcon /> */}
            </TimelineDot>
            <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span">
            Prof. dr. Razija Lagumdžija
            </Typography>
            {/* <Typography>Because you need strength</Typography> */}
            </TimelineContent>
        </TimelineItem>
      
<TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          septembar 1986. - august 1988.
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            {/* <LaptopMacIcon /> */}
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
          Prof. Borislav Stjepanović
          </Typography>
          {/* <Typography>Because it&apos;s awesome!</Typography> */}
        </TimelineContent>
</TimelineItem>
      
        <TimelineItem>
            <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
            >
            septembar 1988. - april 1989.
            </TimelineOppositeContent>
            <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
                {/* <FastfoodIcon /> */}
            </TimelineDot>
            <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span">
            Prof. dr. Miroslav Avram
            </Typography>
            {/* <Typography>Because you need strength</Typography> */}
            </TimelineContent>
        </TimelineItem>

<TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          maj 1989. - maj 1992.
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            {/* <LaptopMacIcon /> */}
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
          Prof. dr. Nenad Vuković
          </Typography>
          {/* <Typography>Because it&apos;s awesome!</Typography> */}
        </TimelineContent>
</TimelineItem>

        <TimelineItem>
                    <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                    >
                juni 1992. – januar 1993.
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color="primary">
                        {/* <FastfoodIcon /> */}
                    </TimelineDot>
                    <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                    Prof. dr. Dževad Karahasan
                    </Typography>
                    {/* <Typography>Because you need strength</Typography> */}
                    </TimelineContent>
        </TimelineItem>

<TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
         februar – oktobar 1993.
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            {/* <LaptopMacIcon /> */}
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
          Prof. dr. Milan Šipka
          </Typography>
          {/* <Typography>Because it&apos;s awesome!</Typography> */}
        </TimelineContent>
</TimelineItem>

        <TimelineItem>
                            <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="text.secondary"
                            >
                        novembar 1993 - august 2005
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color="primary">
                                {/* <FastfoodIcon /> */}
                            </TimelineDot>
                            <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span">
                            prof. Admir Glamočak
                            </Typography>
                            {/* <Typography>Because you need strength</Typography> */}
                            </TimelineContent>
        </TimelineItem>

<TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          septembra 2005 – oktobar 2013.
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            {/* <LaptopMacIcon /> */}
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
          prof. Zijad Mehić
          </Typography>
          {/* <Typography>Because it&apos;s awesome!</Typography> */}
        </TimelineContent>
</TimelineItem>

        <TimelineItem>
                            <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="text.secondary"
                            >
                        2013 - 2019
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color="primary">
                                {/* <FastfoodIcon /> */}
                            </TimelineDot>
                            <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span">
                            prof. Pjer Žalica
                            </Typography>
                            {/* <Typography>Because you need strength</Typography> */}
                            </TimelineContent>
        </TimelineItem>
        <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
         2019 – 2021
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            {/* <LaptopMacIcon /> */}
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
          prof. Srđan Vuletić
          </Typography>
          {/* <Typography>Because it&apos;s awesome!</Typography> */}
        </TimelineContent>
</TimelineItem>


    </Timeline>
            </div>
        </div>
    );
}

export default DekaniHistory;
