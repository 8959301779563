import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FadeLoader } from 'react-spinners';

const OdsijekDetalj = () => {
  const { id } = useParams();
  const [odsjek, setOdsjek] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const storedLanguage = localStorage.getItem('selectedLanguage');

  useEffect(() => {
    Axios.get(`https://asu.forward.ba/wp-json/wp/v2/pages/${id}?_embed`)
      .then((res) => {
        setOdsjek(res.data);
        setLoading(false);
      })
      .catch(() => {
        console.log('There was an error while retrieving the data');
      });
  }, [id]);

  const naslov = storedLanguage === 'en' ? odsjek?.acf?.prevod_nasova : odsjek?.title?.rendered || '';
  const opis = storedLanguage === 'en' ? odsjek?.acf?.prevod_teksta : odsjek?.content?.rendered || '';

   // Funkcija koja zamjenjuje linkove na PDF-ove
   const prikaziOpis = {
    replace: (domNode) => {
        if (domNode?.name === 'a' && domNode?.attribs?.href?.endsWith('.pdf')) {
            return (
                <a href={domNode.attribs.href} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '5px' }} />
                    {domNode.children[0]?.data || "PDF File"}
                </a>
            );
        }
        return null;
    },
};

  const backgroundImageUrl = odsjek?._embedded?.['wp:featuredmedia']?.[0]?.media_details?.sizes?.full?.source_url || '';

  return (
    <>
      <main role="main">
        {loading ? (
          <div
            className="text-center"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '70vh',
            }}
          >
            <FadeLoader color={"#123abc"} loading={loading} size={100} />
          </div>
        ) : (
          <div
            className="row hoverStyle oodsjekDetalj"
            style={{
              backgroundImage: `url(${backgroundImageUrl})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover', // Ovo će osigurati da slika pokriva cijelo područje
            }}
          >
            <div className="col drugi odsjekDetalj" style={{ height: 'auto !important' }}>
              <div className="card upisFront">
                <div className="card-body">
                  <h1 className="card-title">{naslov ? parse(naslov) : ''}</h1>
                  <div> {parse(opis, prikaziOpis)}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </>
  );
};

export default OdsijekDetalj;
