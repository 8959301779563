import React from 'react';

const Sadrzaj = ({ htmlContent }) => {
  // Uklanjanje img tagova iz htmlContent
  const sanitizedContent = htmlContent.replace(/<img.*?>/g, '');

  return (
    <div id="stilizirani-div" dangerouslySetInnerHTML={{ __html: sanitizedContent }}></div>
  );
};

export default Sadrzaj;
