import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Select,
  MenuItem,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { ImFilePdf, ImImage, ImFileWord, ImFileExcel } from "react-icons/im";
import axios from "axios";
import { FadeLoader } from "react-spinners";

const IzvjestajOradu = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;

  const [selectedYear, setSelectedYear] = useState("Sve");

  useEffect(() => {
    async function fetchData() {
      let allPosts = [];
      let totalPages = 1;
      let currentPage = 1;

      while (currentPage <= totalPages) {
        const response = await axios.get(
          `https://asu.forward.ba/wp-json/wp/v2/posts?categories=9&_embed&?_fields=acf&acf_format=standard&per_page=${recordsPerPage}&page=${currentPage}`
        );

        const responseData = response.data;
        allPosts = allPosts.concat(responseData);
        totalPages = response.headers["x-wp-totalpages"];
        currentPage++;
      }

      allPosts.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
      });

      setData(allPosts);
      setFilter(allPosts);
      setLoading(false);
    }

    fetchData();
  }, []);

  const nPages = Math.ceil(filter.length / recordsPerPage);

  const requestSearch = (searchedVal) => {
    const filteredRows = data.filter((row) => {
      return row.title.rendered
        .toString()
        .toLowerCase()
        .includes(searchedVal.toString().toLowerCase());
    });
    if (searchedVal.length < 1) {
      setFilter(data);
    } else {
      setFilter(filteredRows);
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filter.slice(indexOfFirstRecord, indexOfLastRecord);

  const handleYearFilterChange = (event) => {
    const selectedYear = event.target.value;
    setSelectedYear(selectedYear);

    if (selectedYear === "Sve") {
      setFilter(data);
    } else {
      const filteredPosts = data.filter((post) => {
        const postYear = new Date(post.date).getFullYear().toString();
        return postYear === selectedYear;
      });
      setFilter(filteredPosts);
    }
  };

  const availableYears = [
    ...new Set(data.map((post) => new Date(post.date).getFullYear().toString())),
  ];

  return (
    <div className="container drugaSekcija dekanat">
      <div className="container dekanat">
        <div className="container drugaSekcija">
          <h1>Izvještaj o radu</h1>
          {loading ? (
            <div
              className="text-center"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
              }}
            >
              <FadeLoader color={"#123abc"} loading={loading} size={100} />
            </div>
          ) : (
            <div className="d-flex bd-highlight">
              <div className="p-2 flex-fill bd-highlight">
                <TextField
                  onChange={(e) => requestSearch(e.target.value)}
                  placeholder="Traži dokument"
                />
                <Select
                  value={selectedYear}
                  onChange={handleYearFilterChange}
                  displayEmpty
                >
                  <MenuItem value="Sve">Sve godine</MenuItem>
                  {availableYears.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Naziv dokumenta</TableCell>
                        <TableCell>Datum objave</TableCell>
                        <TableCell>Preuzmi</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentRecords.map((row, index) => (
                        <TableRow key={row.id}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{row.title.rendered}</TableCell>
                          <TableCell>
                            {new Date(row?.date).toLocaleDateString()}
                          </TableCell>
                          <TableCell>
                            {row?.acf?.dokumenti ? (
                              row?.acf?.dokumenti.map((el) => {
                                return (
                                  <React.Fragment
                                    key={el?.naziv_dokumenta}
                                  >
                                    {(() => {
                                      if (el.dokument.subtype === "pdf") {
                                        return (
                                          <a href={el?.dokument?.url}>
                                            <ImFilePdf size="25" />
                                          </a>
                                        );
                                      } else if (
                                        el?.dokument?.subtype === "jpeg" ||
                                        el?.dokument?.subtype === "jpg" ||
                                        el?.dokument?.subtype === "png"
                                      ) {
                                        return (
                                          <a href={el?.dokument?.url}>
                                            <ImImage size="25" />
                                          </a>
                                        );
                                      } else if (
                                        el?.dokument?.subtype === "docx" ||
                                        el?.dokument?.subtype === "doc"
                                      ) {
                                        return (
                                          <a href={el?.dokument?.url}>
                                            <ImFileWord size="25" />
                                          </a>
                                        );
                                      } else if (
                                        el?.dokument?.subtype ===
                                          "vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                        el?.dokument?.subtype === "vnd.ms-excel"
                                      ) {
                                        return (
                                          <a href={el?.dokument?.url}>
                                            <ImFileExcel size="25" />
                                          </a>
                                        );
                                      } else {
                                        return <p>Nema dokumenta</p>;
                                      }
                                    })()}
                                  </React.Fragment>
                                );
                              })
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  count={nPages}
                  page={currentPage}
                  onChange={handleChangePage}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IzvjestajOradu;
