import React, { useState, useEffect } from "react";
import './Header.css'
import { useParams,Link, useLocation } from 'react-router-dom';
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Dropdown } from 'react-bootstrap';
//drawer elements used
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FolderIcon from "@mui/icons-material/Folder";
import ImageIcon from "@mui/icons-material/Image";
import DescriptionIcon from "@mui/icons-material/Description";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import { useTranslation } from 'react-i18next';
import { NavDropdown } from "react-bootstrap";


const StyledSearch = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.25)
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto"
  }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch"
    }
  }
}));

const search = (
  <StyledSearch>
    <SearchIconWrapper>
      <SearchIcon />
    </SearchIconWrapper>
    <StyledInputBase
      placeholder="Search…"
      inputProps={{ "aria-label": "search" }}
    />
  </StyledSearch>
);

export default function Header() {
  const [open, setState] = useState(false);
  let { id } = useParams();
  // console.log("id", id)
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('selectedLanguage') || 'bs'
  ); // Default language is English
  const location = useLocation();
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // Retrieve the selected language from local storage and set it as the initial language
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
const handleLanguageChange = (language) => {
  // console.log(language)
  setSelectedLanguage(language);
  i18n.changeLanguage(language);
  // Save the selected language to local storage
  localStorage.setItem('selectedLanguage', language);
};

  return (
    <AppBar position="static" style={{backgroundColor:"white"}}>
        <nav class="py-2 bg-light mobile-nav">
    <div class="d-flex justify-content-end">
      <ul class="nav">
      
        <li class="nav-item"><Link className={`nav-link link-dark px-2`} to="/novosti">{t('news')}</Link><span id="meni">|</span></li>
        <li class="nav-item"><Link class="nav-link link-dark px-2" to="https://www.isss.ba/LoginPage/login.htm">ISSS</Link><span id="meni">|</span></li>
        <li class="nav-item"><Link className={`nav-link link-dark px-2`} to="/nova-zgrada">Nova zgrada</Link><span id="meni">|</span></li>
         
        <li class="nav-item"><Link className={`nav-link link-dark px-2`} to="/medjunarodna-saradnja">{t('international')}</Link><span id="meni">|</span></li>
        <li class="nav-item"><Link class="nav-link link-dark px-2" to="https://asu.forward.ba/wp-content/uploads/2024/03/2024_brosura_asu.pdf">Brošura</Link><span id="meni">|</span></li>
        <li class="nav-item">
        <Link class="nav-link link-dark px-2" to="/alumni">Alumni</Link>
        </li>
      </ul>
      <form class="d-flex">
        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
        
      </form>
      <ul class="nav">
        
        <li class="nav-item"><p onClick={() => handleLanguageChange('en')} class="nav-link link-dark px-2">EN</p><span id="meni"></span></li>
        <li class="nav-item"><p onClick={() => handleLanguageChange('bs')} class="nav-link link-dark px-2">BH</p><span id="meni"></span></li>
      </ul>
    </div>
  </nav>
      <Container maxWidth="lg" disableGutters={true} style={{minHeight:"88px", marginLeft:0, maxWidth:"100%"}}>
    
        <Toolbar>
        <div className="logoMobilna" style={{width: "26%",marginLeft: "0",marginRight: "0"}}>
    <a class="navbar-brand" href="#">
    <img 
      // class="logo"  
      src="./media/images/logo_unsa.svg" 
      alt="" 
      // width="150px" 
      // style={{padding:6}}
      height="55px"

      />
      <img 
      class="logo"  
      src="./media/images/asu_logo.jpg" 
      alt="" 
      // width="500" 
      height="auto"
      />
    </a>
  </div>
          {/* <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: 700 }} style={{color:"black"}}>
            Brand
          </Typography> */}
          <ul className="nav mobile-nav" style={{marginLeft:"0", marginTop:30}}>
          <li className="nav-item display-lg">
          <Link
      className={`nav-link meni ${location.pathname === '/' ? 'active' : ''}`}
      to="/"
    >
      {t('home')}
    </Link>
          {/* <Link class="nav-link meni active" aria-current="page" to="/">{t('home')}</Link> */}
          </li>
          <li class="nav-item dropdown">
        <Dropdown>
        <Dropdown.Toggle variant="nav-link meni" id="dropdown-basic">
        {t('aboutus')}
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <Dropdown.Item><Link to="/dekanat">{t('deanery')}</Link></Dropdown.Item>
            <Dropdown.Item><Link to="/tijela-asu">Tijela Akademije</Link></Dropdown.Item>
            <Dropdown.Item><Link to="/nagrada-razija-lagumdzija">Nagrada "Razija Lagumdžija"</Link></Dropdown.Item>
            <Dropdown.Item><Link to="/radili-na-asu">Radili na ASU</Link></Dropdown.Item>
            <Dropdown.Item><Link to="/in-memoriam">In Memoriam</Link></Dropdown.Item>
            <Dropdown.Item><Link to="/dekani-kroz-historiju">Dekani kroz historiju</Link></Dropdown.Item>
            <Dropdown.Item><Link to="/politika-osiguranja-kvaliteta-asu">Politika osiguranja kvaliteta ASU</Link></Dropdown.Item>
            <Dropdown.Item><Link to="/misljenja-etickog-odbora">Misljenja etičkog odbora</Link></Dropdown.Item>
            <Dropdown.Item><Link to="/kontakt">Kontakt</Link></Dropdown.Item>
            {/* <Dropdown.Item><Link to="/dekani-kroz-historiju">Dekani kroz historiju</Link></Dropdown.Item> */}
        </Dropdown.Menu>
        </Dropdown>
    
        </li>
        <li class="nav-item dropdown">
        <Dropdown>
        <Dropdown.Toggle variant={`nav-link meni ${location.pathname === '/opsti-akti' ? 'active' : ''}`} id="dropdown-basic">
        {t('documents')}
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <Dropdown.Item> <Link class="nav-link" to="/opsti-akti">Opšti akti</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/odluke">Odluke</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/javne-nabavke">Javne nabavke</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/izvjestaj-o-radu">Izvjesaj o radu</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/javnost-rada">Javnost rada (NUV)</Link></Dropdown.Item>
        </Dropdown.Menu>
        </Dropdown>
        </li>
        {/* <li class="nav-item">
        <Link class="nav-link meni" to="/dekanat">{t('deanery')}</Link>
        </li> */}
        <li class="nav-item">
        <Link className={`nav-link meni ${location.pathname === '/odsjeci' ? 'active' : ''}`} to="/odsjeci">{t('departments')}</Link>
        </li>
        <li class="nav-item dropdown">
        <Dropdown>
        <Dropdown.Toggle variant="nav-link meni" id="dropdown-basic"> {t('teachers')}</Dropdown.Toggle>

        <Dropdown.Menu>
        <Dropdown.Item> <Link class="nav-link" to="/redovni">Redovni profesori</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/vanredni">Vanredni profesori</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/docenti">Docenti</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/visi-ass">Viši Asistenti</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/ass">Asistenti</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/saradnici">Saradnici</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/izbori-u-zvanja">Izbori u zvanja</Link></Dropdown.Item>
        </Dropdown.Menu>
        </Dropdown>
        </li>
       
        {/* <li class="nav-item">
          <Link class="nav-link" to="/nastavnici">Nastavnici</Link>
        </li> */}
        <li class="nav-item">
        {/* <Link class="nav-link" to="/studenti">Studenti</Link> */}
        <NavDropdown title="Studenti" id="studenti-dropdown" style={{color: "black",fontWeight: 700}}>
      <NavDropdown.Item as={Link} to="/studenti-dramaturgija">
        Odsjek za dramaturgiju
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/studenti-gluma">
        Odsjek za glumu
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/studenti-produkcija">
        Odsjek za produkciju
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/studenti-rezija">
        Odsjek za režiju
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/studenti-diplomirali">
        Diplomirali na ASU
      </NavDropdown.Item>
      <NavDropdown.Item href="https://www.asu.unsa.ba/evidencije/?page_id=2" target="_blank" rel="noopener noreferrer">
        Bodovi
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/studenti-nastavni-proces">
        Nastavni proces
      </NavDropdown.Item>
    </NavDropdown>
          {/* <a class="nav-link" href="#">Studenti</a> */}
        </li>
        <li class="nav-item dropdown">
        {/* <Dropdown>
    <Dropdown.Toggle variant="nav-link meni" id="dropdown-basic">Dokumentacioni centar</Dropdown.Toggle>

    <Dropdown.Menu>
        <Dropdown.Item><Link className="nav-link" to="/o-biblioteci">O biblioteci</Link></Dropdown.Item>
        <Dropdown.Item><a className="nav-link" href="https://bh.cobiss.net/" target="_blank" rel="noopener noreferrer">Mrezni katalog</a>
</Dropdown.Item>
        <Dropdown.Item><Link className="nav-link" to="/baze-podataka">Baze podataka</Link></Dropdown.Item>
        <Dropdown.Item><Link className="nav-link" to="/izdavastvo">Izdavastvo</Link></Dropdown.Item>
        <Dropdown.Item><Link className="nav-link" to="/bilioteka-obavijesti">Obavijesti</Link></Dropdown.Item>
    </Dropdown.Menu>
</Dropdown> */}
<NavDropdown title="Dokumentacioni centar" id="nav-dropdown" style={{color: "black",fontWeight: 700}}>
    <NavDropdown.Item className="nav-link" as={Link} to="/o-biblioteci">
        O biblioteci
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to="/bilioteka-obavijesti">
        Obavijesti
    </NavDropdown.Item>
    <NavDropdown.Item href="https://bh.cobiss.net/" target="_blank" rel="noopener noreferrer">
        Mrezni katalog
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to="/baze-podataka">
        Baze podataka
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to="/izdavastvo">
        Izdavastvo
    </NavDropdown.Item>
    <NavDropdown.Item href="https://drive.google.com/drive/u/1/folders/0B7tD2S0d-cHBVmZTOEtTUmFfaVk?resourcekey=0-u0pbt4JEYDxpaQO_oPoL8Q" target="_blank" rel="noopener noreferrer">
       Studentski radovi
    </NavDropdown.Item>
    <NavDropdown.Item href="https://docs.google.com/forms/d/e/1FAIpQLSelDrEaMPU0XEK5Flr5F9pblYF-SSWus2Pe6woOr4M34H8Y6A/viewform" target="_blank" rel="noopener noreferrer">
       Obrazac za film
    </NavDropdown.Item>
    <NavDropdown.Item href="https://docs.google.com/forms/d/e/1FAIpQLSdux52pEBzhpETkYXC0eKw4ecNbbatsiWDL-f2BxXVdvn6eog/viewform" target="_blank" rel="noopener noreferrer">
       Obrazac za pozorište
    </NavDropdown.Item>
    <NavDropdown.Item href="https://asu.forward.ba/wp-content/uploads/2023/11/Prirucnik_za_navodjenje_Akademija_scenskih_umjetnosti_Sarajevo.pdf" target="_blank" rel="noopener noreferrer">
       Priručnik za navođenje izvora
    </NavDropdown.Item>
    <NavDropdown.Item href="https://cris.cobiss.net/ecris/bh/bs" target="_blank" rel="noopener noreferrer">
      E-CRIS.BH 
    </NavDropdown.Item>
</NavDropdown>


        </li>

         {/* <li class="nav-item">
          <a class="nav-link" href="#">Dokumentacioni centar</a>
        </li> */}
         <li class="nav-item">
         <Link className={`nav-link meni ${location.pathname === '/obala' ? 'active' : ''}`} to="/obala">Obala</Link>
        </li>
       
      </ul>
  
<div className="samoNaMobilnoj" style={{ marginLeft: 'auto' }}>
    {/* Vaš element */}
    <IconButton
      edge="end"
      color="black"
      aria-label="open drawer"
      onClick={toggleDrawer(true)}
      
    >
      <MenuIcon />
    </IconButton>
  </div>

          <Drawer
            anchor="right"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}    
      
          >
            <Box
              sx={{
               
                p: 2,
                height: 1,
                // backgroundColor: "#dbc8ff"
               
              }}
            >
              <IconButton sx={{ mb: 2 }} color="black">
                <CloseIcon onClick={toggleDrawer(false)} />
              </IconButton>
              <ul class="nav">
              <li class="nav-item"><p onClick={() => handleLanguageChange('en')} class="nav-link link-dark px-2">EN</p><span id="meni"></span></li>
              <li class="nav-item"><p onClick={() => handleLanguageChange('bs')} class="nav-link link-dark px-2">BH</p><span id="meni"></span></li>
              </ul>
     
              <Divider sx={{ mb: 2 }} />

              <Box sx={{ mb: 2 }}>
           
        <Dropdown>
        <Dropdown.Toggle variant="nav-link" id="dropdown-basic">
        {t('aboutus')}
        </Dropdown.Toggle>

        <Dropdown.Menu>
        <Dropdown.Item><Link to="/dekanat">{t('deanery')}</Link></Dropdown.Item>
            <Dropdown.Item><Link to="/tijela-asu">Tijela Akademije</Link></Dropdown.Item>
            <Dropdown.Item><Link to="/nagrada-razija-lagumdzija">Nagrada "Razija Lagumdžija"</Link></Dropdown.Item>
            <Dropdown.Item><Link to="/radili-na-asu">Radili na ASU</Link></Dropdown.Item>
            <Dropdown.Item><Link to="/in-memoriam">In Memoriam</Link></Dropdown.Item>
            <Dropdown.Item><Link to="/dekani-kroz-historiju">Dekani kroz historiju</Link></Dropdown.Item>
            <Dropdown.Item><Link to="/politika-osiguranja-kvaliteta-asu">Politika osiguranja kvaliteta ASU</Link></Dropdown.Item>
            <Dropdown.Item><Link to="/misljenja-etickog-odbora">Mišljenja etičkog odbora</Link></Dropdown.Item>
            <Dropdown.Item><Link to="/kontakt">Kontakt</Link></Dropdown.Item>
            {/* <Dropdown.Item><Link to="/dekani-kroz-historiju">Dekani kroz historiju</Link></Dropdown.Item> */}
        </Dropdown.Menu>
        </Dropdown>
    
        <Dropdown>
        <Dropdown.Toggle variant="nav-link" id="dropdown-basic">
        {t('documents')}
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <Dropdown.Item> <Link class="nav-link" to="/opsti-akti">Opšti akti</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/odluke">Odluke</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/javne-nabavke">Javne nabavke</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/izvjestaj-o-radu">Izvještaj o radu</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/javnost-rada">Javnost rada (NUV)</Link></Dropdown.Item>
        </Dropdown.Menu>
        </Dropdown>
        {/* <ListItemButton>
                   <Link class="nav-link meni" to="/dekanat">{t('deanery')}</Link>
                </ListItemButton> */}
        <ListItemButton>
        <Link class="nav-link meni" to="/odsjeci">{t('departments')}</Link>
        </ListItemButton>
        <Dropdown>
        <Dropdown.Toggle variant="nav-link" id="dropdown-basic"> {t('teachers')}</Dropdown.Toggle>

        <Dropdown.Menu>
        <Dropdown.Item> <Link class="nav-link" to="/redovni">Redovni profesori</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/vanredni">Vanredni profesori</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/docenti">Docenti</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/visi-ass">Viši Asistenti</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/ass">Asistenti</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/saradnici">Saradnici</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/izbori-u-zvanja">Izbori u zvanja</Link></Dropdown.Item>
        </Dropdown.Menu>
        </Dropdown>
        <Dropdown>
        <Dropdown.Toggle variant="nav-link" id="dropdown-basic">Studenti</Dropdown.Toggle>

        <Dropdown.Menu>
        <Dropdown.Item> <Link class="nav-link" to="/studenti-dramaturgija">Odsjek za dramaturgiju</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/studenti-gluma">Odsjek za glumu</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/studenti-produkcija">Odsjek za produkciju</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/studenti-rezija">Odsjek za režiju</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/studenti-diplomirali">Diplomirali na ASU</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/studenti-bodovi">Bodovi</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/studenti-nastavni-proces">Nastavni proces</Link></Dropdown.Item>
        </Dropdown.Menu>
        </Dropdown>
        
        <Dropdown>
        <Dropdown.Toggle variant="nav-link" id="dropdown-basic">Dokumentacioni centar</Dropdown.Toggle>

        <Dropdown.Menu>
        <Dropdown.Item> <Link class="nav-link" to="/o-biblioteci">O biblioteci</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/mrezni-katalog">Mrezni katalog</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/baze-podataka">Baze podataka</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/izdavastvo">Izdavastvo</Link></Dropdown.Item>
            <Dropdown.Item> <Link class="nav-link" to="/bilioteka-obavijesti">Obavijesti</Link></Dropdown.Item>
            <Dropdown.Item href="https://drive.google.com/drive/u/1/folders/0B7tD2S0d-cHBVmZTOEtTUmFfaVk?resourcekey=0-u0pbt4JEYDxpaQO_oPoL8Q" target="_blank" rel="noopener noreferrer">
       Studentski radovi
    </Dropdown.Item>
    <Dropdown.Item href="https://docs.google.com/forms/d/e/1FAIpQLSelDrEaMPU0XEK5Flr5F9pblYF-SSWus2Pe6woOr4M34H8Y6A/viewform" target="_blank" rel="noopener noreferrer">
       Obrazac za film
    </Dropdown.Item>
    <Dropdown.Item href="https://docs.google.com/forms/d/e/1FAIpQLSdux52pEBzhpETkYXC0eKw4ecNbbatsiWDL-f2BxXVdvn6eog/viewform" target="_blank" rel="noopener noreferrer">
       Obrazac za pozorište
    </Dropdown.Item>
    <Dropdown.Item href="https://asu.forward.ba/wp-content/uploads/2023/11/Prirucnik_za_navodjenje_Akademija_scenskih_umjetnosti_Sarajevo.pdf" target="_blank" rel="noopener noreferrer">
       Priručnik za navođenje izvora
    </Dropdown.Item>
    <Dropdown.Item href="https://cris.cobiss.net/ecris/bh/bs" target="_blank" rel="noopener noreferrer">
      E-CRIS.BH 
    </Dropdown.Item>
        </Dropdown.Menu>
        </Dropdown>
        <ListItemButton>
        <Link class="nav-link meni" to="/obala">Obala</Link>
        </ListItemButton>
        <ListItemButton>
        <Link className="nav-link meni" to="/novosti">{t('news')}</Link>
        </ListItemButton>
        <ListItemButton>
        <Link class="nav-link meni" to="/obala">ISSS</Link>
        </ListItemButton>
        <ListItemButton>
        <Link class="nav-link meni" to="/obala">Nova zgrada ASU</Link>
        </ListItemButton>
        <ListItemButton>
        <Link class="nav-link meni" to="/obala">Međunarnodna saradnja</Link>
        </ListItemButton>
        <ListItemButton>
        <Link class="nav-link meni" to="https://asu.forward.ba/wp-content/uploads/2024/03/2024_brosura_asu.pdf">Brošura</Link>
        </ListItemButton>
        <ListItemButton>
        <Link class="nav-link meni" to="/alumni">Alumni</Link>
        </ListItemButton>
       
                {/* <ListItemButton>
                  <ListItemIcon>
                    <FolderIcon sx={{ color: "primary.main" }} />
                  </ListItemIcon>
                  <ListItemText primary="Other" />
                </ListItemButton> */}
              </Box>

              {/* {search} */}

              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  position: "absolute",
                  bottom: "0",
                  left: "50%",
                  transform: "translate(-50%, 0)"
                }}
              > */}
                {/* <Button variant="contained" sx={{ m: 1, width: 0.5 }}>
                  Register
                </Button>
                <Button variant="outlined" sx={{ m: 1, width: 0.5 }}>
                  Login
                </Button> */}
              {/* </Box> */}

            </Box>
          </Drawer>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
