import React, { useState, useEffect } from "react";
// import './style.css';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { FadeLoader } from "react-spinners"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const Dekanat = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('selectedLanguage');

    useEffect(() => {
        axios.get("https://asu.forward.ba/wp-json/wp/v2/pages/193")
            .then((res) => {
                console.log(res)
                setData(res.data);
                setLoading(false);
            })
            .catch(() => {
                alert('There was an error while retrieving the data')
            })
    }, [])

    // Proverite da li su podaci učitani pre nego što pristupite svojstvima
    
    // if (loading) {
    //     return <div>Loading...</div>;
    // }

    const naslov = storedLanguage === 'en' ? data?.acf?.prevod_nasova || "" : data?.title?.rendered || "";
    const opis = storedLanguage === 'en' ? data?.acf?.prevod_teksta || "" : data?.content?.rendered || "";

    const prikaziOpis = parse(opis, {
        replace: (domNode) => {
            if (domNode.name === 'a' && domNode.attribs.href && domNode.attribs.href.endsWith('.pdf')) {
                return (
                    <a href={domNode.attribs.href} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '5px' }} />
                        {domNode.children[0] && domNode.children[0].data}
                    </a>
                );
            }
        },
    });
    
    return (
        <div className="container drugaSekcija dekanat">
            <div className="container dekanat">
                 {/* Prikazuje spinner dok se podaci učitavaju */}
                 <h1>{parse(naslov)}</h1>
                 {loading ? (
                          <div
                          className="text-center"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '70vh', // Postavite visinu na visinu ekrana (100% viewport visine)
                          }}
                        >
                          <FadeLoader color={"#123abc"} loading={loading} size={100} />
                        </div>
                    ) : (
                <div className="container drugaSekcija">
                    {/* <h1>{parse(naslov)}</h1> */}
                    <div className="d-flex bd-highlight">
                        <div className="p-2 flex-fill bd-highlight">
                        {prikaziOpis}
                        </div>
                    </div>
                </div>
                  )}
            </div>
        </div>
    );
}

export default Dekanat;


// import React from 'react'


// const Dekanat = () =>{

//     return(
//         <>
// <div class="container drugaSekcija dekanat">
//  <div class="container dekanat">
//     <div class="container drugaSekcija">
//         <h1>UPRAVA I SLUŽBE</h1>
//         <div class="d-flex bd-highlight">
//             <div class="p-2 flex-fill bd-highlight">
//                 <p>DEKAN AKADEMIJE:</p>
//                 <p><strong>van. prof. mr. Faruk LONČAREVIĆ </strong></p>
//                  <p>e-mail: faruk.loncarevic@asu.unsa.ba</p>
//                  <p>PRODEKAN ZA NASTAVU I STUDENTSKA PITANJA: </p>
//                 <p>doc. Zoran MLINAREVIĆ</p>
//                 <p> e-mail: zoran.mlinarevic@asu.unsa.ba</p>
//                 <p>PRODEKANESA ZA RAZVOJ, FINANSIJE I PRODUKCIJU:</p> 
//                 <p>-</p>
//                 <p> e-mail: </p>
//                 <p>PRODEKANESA ZA MEĐUNARODNU SARADNJU,</p> 
//                 <p>UMJETNIČKI I NASTAVNO ISTRAŽIVAČKI RAD:</p>
//                 <p>prof. dr. Elma TATARAGIĆ</p>
//                  <p>e-mail: elma.tataragic@asu.unsa.ba</p>
//                  <p>STUDENT PRODEKAN:</p>
//             <p>Mia AVDAGIĆ</p>
//             <p>e-mail: mavdagic@asu.unsa.ba</p>
               
//             </div>
//         <div class="p-2 flex-fill bd-highlight">
//             <p>SEKRETAR:</p>
//             <p>Ornela ĐAKUŠIĆ, sekretar Akademije </p>
//             <p>e-mail: sekretar@asu.unsa.ba</p>
//             <p>STUDENTSKA I ADMINISTRATIVNA SLUŽBA:</p> 
//             <p>Sabiha ALIBAŠIĆ, šefica studentske službe i administrativno-opće službe</p>
//             <p>e-mail: studentskasluzba@asu.unsa.ba </p>
//             <p>Emina Pecikoza, Viši samostalni referent – tehnički sekretar</p>
//             <p>e-mail: asu@asu.unsa.ba</p>
//             <p>Aida HAMZIĆ, Viši stručni saradnik za pravne poslove</p>
//             <p>e-mail: sekretarijat@asu.unsa.ba</p>
//             <p>RAČUNOVODSTVO:</p>
//             <p>Emina NALO, šefica računovodstva</p>
//             <p>e-mail: racunovodstvo@asu.unsa.ba</p>
//             <p>Daria ŽDRALE, Stručni saradnik za računovodstvo i finansije</p> 
//             <p>e-mail: racunovodstvo@asu.unsa.ba</p>
      
//          </div>
        
//          <div class="p-2 flex-fill bd-highlight">
               
//                  {/* <p>KOORDINATOR ZA NASTAVNU PRODUKCIJU: </p> */}
//                  <p>Rusmir EFENDIĆ, Šef službe za podršku</p>
//                  <p>e-mail: tehnika@asu.unsa.ba</p>
//                  <p>DOKUMENTACIONI CENTAR: (Biblioteka-Videoteka-Fototeka)</p>
//                  <p>-</p>
//                  <p>e-mail:</p>
//                  <p>IT ODJEL </p>
//                  <p>Sead KARKELJA, Šef IT odjela</p>
//                  <p>e-mail:sead.karkelja@asu.unsa.ba</p>
                
//         </div>
           
        
//             <div class="p-2 flex-fill bd-highlight">
//                 <p>TEHNIKA:</p>
//                 <p>Alen KAIKČIJA, tehničar / laborant</p> 
//                  <p>Admir ŠATARA, tehničar / laborant</p>  
//                  <p>Aida HASANOVIĆ, garderoberka</p> 
//                  <p>TEHNIČKI POSLOVI AKADEMIJE: </p> 
//                  <p>Admir ŽUNIĆ, domaćin i ekonom / kurir</p> 
//                  <p>Zejna ČELIK, spremačica</p> 
//                  <p>Aida POROBIĆ, spremačica</p> 
            
//             </div>
        
//             {/* <div class="p-2 flex-fill bd-highlight">
//                  </div> */}
         
//         </div>
//   </div>
// </div>
// </div>
//         </>
//     )
// }

// export default Dekanat;