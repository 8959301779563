import React,{useState, useEffect} from 'react'
import Axios from 'axios';
import {Link, useParams} from 'react-router-dom';
import { ImFilePdf, ImImage, ImFileWord, ImFileExcel } from 'react-icons/im';
import { FadeLoader } from "react-spinners"; 

const DiplomiraliDetalj = () =>{
    const {id} = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        Axios.get(`https://asu.forward.ba/wp-json/wp/v2/pages/${id}?_embed&?_fields=acf&acf_format=standard`)
        .then((res)=>{
            setData(res.data);
            setLoading(false);
        })
    },[])
    // console.log(data)
    // console.log(data?.acf)
    return(
        <>
   <div class="container drugaSekcija dekanat nastavnik">
 <div class="container dekanat">
 {loading ? (
                        <div
                        className="text-center"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '70vh', // Postavite visinu na visinu ekrana (100% viewport visine)
                        }}
                      >
                        <FadeLoader color={"#123abc"} loading={loading} size={100} />
                      </div>
                    ) : (
    <main role="main" class="container drugaSekcija">
            <div class="row">
            <h1 style={{color:"black", fontWeight:'normal', fontSize:"2rem"}}>{data?.title?.rendered}</h1>
              <div class="col-md-8">
              <div dangerouslySetInnerHTML={{ __html: data?.content?.rendered }} ></div>

              </div>
              
            </div>
           <div class="row">
         
    
            <hr/>  
            </div>                     
        </main>
          )}
        </div>
        </div>
        </>
    )
}

export default DiplomiraliDetalj;