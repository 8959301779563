import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { FadeLoader } from "react-spinners"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import '../../App.css';

const Popup = () => {
  const cookies = new Cookies();
  const [showPopup, setShowPopup] = useState(true); // Ovdje mijenjamo na true
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation();
  const storedLanguage = localStorage.getItem('selectedLanguage');

  useEffect(() => {
    axios.get("https://asu.forward.ba/wp-json/wp/v2/pages/2267")
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch(() => {
        console.log('There was an error while retrieving the data');
      });
  }, []);

  useEffect(() => {
    const popupShown = cookies.get('popupShown');
    if (!popupShown && Object.keys(data).length > 0) { // Provjera da li je objekt data prazan
      setShowPopup(true);
    } else if (Object.keys(data).length === 0) {
      setShowPopup(false);
    }
  }, [data]);

  const handleClosePopup = () => {
    cookies.set('popupShown', true, { path: '/', maxAge: 86400 * 365 });
    setShowPopup(false);
  };

  const handleShowMore = () => {
    setShowMore(true);
  };

  useEffect(() => {
    if (!showPopup) {
      cookies.remove('popupShown', { path: '/' });
    }
  }, [showPopup]);

  // Funkcija za zamjenu PDF linkova
  const prikaziOpis = {
    replace: (domNode) => {
      if (domNode?.name === 'a' && domNode?.attribs?.href?.endsWith('.pdf')) {
        return (
          <a href={domNode.attribs.href} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '5px' }} />
            {domNode?.children[0]?.data || "PDF File"}
          </a>
        );
      }
      return null;
    },
  };

  return (
    <>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h1>{storedLanguage === 'en' ? data?.acf?.prevod_nasova || "" : data?.title?.rendered || ""}</h1>

            {loading ? (
              <div
                className="text-center"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '70vh',
                }}
              >
                <FadeLoader color={"#123abc"} loading={loading} size={100} />
              </div>
            ) : (
              <div className="container drugaSekcija">
                <div className="d-flex bd-highlight">
                  <div className="p-2 flex-fill bd-highlight">
                    {parse(storedLanguage === 'en' ? data?.acf?.prevod_teksta || "" : data?.content?.rendered || "", prikaziOpis).slice(0, 200)}
                  </div>
                </div>
              </div>
            )}
            
            <Button
              onClick={handleClosePopup}
              className="button"
              id="mc-embedded-subscribe"
              name="subscribe"
              type="submit"
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "black",
                color: "white",
              }}
            >
              Zatvori
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
