import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { FadeLoader } from "react-spinners"; 
import { useTranslation } from 'react-i18next';

const Odsjeci = () => {
    const [odsjeci, setOdsjeci] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('selectedLanguage');

    useEffect(() => {
        Axios.get("https://asu.forward.ba/wp-json/wp/v2/pages?categories=3&_embed")
            .then((res) => {
                setOdsjeci(res.data);
                setLoading(false);
            })
            .catch(() => {
                console.log('There was an error while retrieving the data');
            });
    }, []);

    function truncateString(str, maxLength) {
        if (str.length <= maxLength) {
            return str;
        }
        return str.slice(0, maxLength) + '...';
    }

    // Funkcija za poređenje objekata po ID-u
    function compareById(a, b) {
        return a.id - b.id;
    }

    const poredani = odsjeci.sort(compareById);

    return (
        <>
            <main role="main" className="container odjseciBox">
                {loading ? (
                    <div
                        className="text-center"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '70vh', // Visina ekrana
                        }}
                    >
                        <FadeLoader color={"#123abc"} loading={loading} size={100} />
                    </div>
                ) : (
                    <div className="row odsjeci">
                        {poredani.map((el) => {
                            // Prijevod naslova i teksta na osnovu odabranog jezika
                            const naslov = storedLanguage === 'en' ? el.acf?.prevod_nasova : el.title.rendered;
                            const tekst = storedLanguage === 'en' ? el.acf?.prevod_teksta : el.content.rendered;

                            return (
                                <div className="card odsjek" key={el.id}>
                                    {el && el._embedded && el._embedded['wp:featuredmedia'] !== undefined ? (
                                        <Link to={`/odsjeci/${el.id}`} className="card-link">
                                            <img
                                                className="card-img-top"
                                                alt="Slika"
                                                style={{ maxHeight: 300 }}
                                                src={el._embedded['wp:featuredmedia'][0]?.media_details?.sizes?.full?.source_url}
                                            />
                                        </Link>
                                    ) : " "}
                                    
                                    <div className="card-body odsjekCard">
                                        <h1 className="card-title odjseciTitle">
                                            <Link to={`/odsjeci/${el.id}`} className="card-link">
                                                {naslov}
                                            </Link>
                                        </h1>
                                        <div className="card-text" dangerouslySetInnerHTML={{ __html: truncateString(tekst, 500) }}></div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </main>
        </>
    );
}

export default Odsjeci;
