import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import Axios from 'axios';
import Slajder from './Slajder';
import { FadeLoader } from "react-spinners"; 
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
// import { Slider } from '@mui/material';

// import Footer from './Footer';
// import Header from './header';

const Home = () =>{
    
    const [clanci, setClanci]= useState([]);
    const [loading, setLoading] = useState(true);
    const storedLanguage = localStorage.getItem('selectedLanguage');
    useEffect(()=>{
        Axios.get("https://asu.forward.ba/wp-json/wp/v2/posts?categories=1&_embed")
        .then((res)=>{
            // res.data.map((el)=>{
            //    console.log(el._embedded.wp:featuredmedia)
            // })
            setClanci(res.data);
            setLoading(false)
        })
    },[])
    const [data, setData] = useState([]);

    useEffect(()=>{
        Axios.get("https://asu.forward.ba/wp-json/wp/v2/pages?categories=23&_embed")
        .then((res)=>{
            setData(res.data[0])
            setLoading(false);
        })
    },[])

    function truncateString(str, maxLength) {
        if (str.length <= maxLength) {
          return str;
        }
        return str.slice(0, maxLength) + '...';
      }
      
      // Funkcija za poređenje objekata po ID-u
      function compareById(a, b) {
        return a.id - b.id;
      }

    // console.log(clanci[0]?.title?.rendered)
    let date = new Date();
    let date2 = new Date(clanci[0]?.date)


    const naslov = storedLanguage === 'en' ? data?.acf?.prevod_nasova || "" : data?.title?.rendered || "";
    const opis = storedLanguage === 'en' ? data?.acf?.prevod_teksta || "" : data?.content?.rendered || "";

    function truncateString(str, maxLength) {
        if (str.length <= maxLength) {
          return str;
        }
        return str.slice(0, maxLength) + '...';
      }
// console.log("data",data)
// console.log("naslov",opis)
    //datu
    const content = clanci[0]?.content?.rendered;

    // Funkcija za dobivanje samo teksta iz HTML-a
    const extractTextFromHTML = (html) => {
      const doc = new DOMParser().parseFromString(html, 'text/html');
      return doc.body.textContent || "";
    };
    
    const textContent = content ? extractTextFromHTML(content) : '';
    const truncatedContent = textContent ? `${textContent.slice(0, 1200)}...` : '';
    return(
        <>
        <Helmet>
        <title>Univerzitet - Akademija scenskih umjetnosti</title>
        <meta property="og:title" content="Univerzitet - Akademija scenskih umjetnosti" />
        {/* <meta property="og:description" content={novost?.excerpt?.rendered} /> */}
        {/* <meta property="og:image" content={novost?._embedded['wp:featuredmedia'][0]?.media_details?.sizes?.medium?.source_url} /> */}
        {/* Dodajte druge Open Graph meta tagove prema potrebi */}
      </Helmet>
         {loading ? (
                          <div
                          className="text-center"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '70vh', // Postavite visinu na visinu ekrana (100% viewport visine)
                          }}
                        >
                          <FadeLoader color={"#123abc"} loading={loading} size={100} />
                        </div>
                    ) : (
        <div class="container prvaSekcija">
  <div class="row">
    <div class="col">

    <Slajder/>

    </div>
    </div>
        </div>
          )}
        <div class="container drugaSekcija">
        {loading ? (
                        <div
                        className="text-center"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '70vh', // Postavite visinu na visinu ekrana (100% viewport visine)
                        }}
                      >
                        <FadeLoader color={"#123abc"} loading={loading} size={100} />
                      </div>
                    ) : (
         <div 
         class="row hoverStyle front" 
         style={{
          backgroundImage: `url(${process.env.PUBLIC_URL + data && data._embedded && data?._embedded['wp:featuredmedia'][0]?.media_details?.sizes?.full?.source_url})`,
          backgroundRepeat: 'no-repeat',
          
      }}
            >
          <div class="col drugi odsjekDetalj">
          {/* <img src="./media/images/sekcija2.jpeg" class="img-fluid over" alt="..."/> */}
          <div class="card upisFront ">
        <div class="card-body" style={{marginTop: "20%"}}>
          <h1 class="card-title">{parse(naslov)}</h1>
         {/* <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6> */}
        
            <div>{parse(truncateString(opis, 1000))}</div>
           
            <button type="button" class="btn btn-danger prijaviSe"> <Link to={`/stranica/${data?.id}`} class="card-link">Pročitaj više</Link></button>
        </div>
        
      </div>
      
          </div>
          </div>
              )}

        {/* <div class="row hoverStyle">
            <div class="col drugi">
            
            <div class="card upisFront ">
        <div class="card-body">
            <h1 class="card-title">UPIS U NOVU <br/> ŠKOLSKU GODINU</h1>
        
            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
        sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim
        id est laborum.</p>
            <button type="button" class="btn btn-danger prijaviSe">Prijavi se</button>
        </div>
        </div>
            </div>
            </div> */}
        </div>


        <div class="container trecaSekcija">
        <div class="row hoverStyleTri">
            <div class="col treci">
            <div class="card upisFrontTri ">
        <div class="card-body">
            <h1 class="card-title">NOVOSTI:</h1>
           

            <Link to={`/novosti/${clanci[0]?.id}`}><h3 class="card-subtitle mb-2 text-muted">{clanci[0]?.title?.rendered}</h3></Link>
            <span>{`${('0' + date2.getDate()).slice(-2)}/${('0' + (date2.getMonth() + 1)).slice(-2)}/${date2.getFullYear()}`}</span>
           
            {/* <p class="card-text">{removeHTML(el?.content.rendered)}</p> */}
            {/* <div dangerouslySetInnerHTML={{ __html: clanci[0]?.content?.rendered }} /> */}
            <div dangerouslySetInnerHTML={{ __html: truncatedContent }} />
            {/* <img
            alt="example"
            src={el._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url}
            /> */}
               
       
            {/* <button type="button" class="btn btn-danger">Prijavi se</button>  */}
        </div>
        </div>
            </div>
            </div>
        </div>
        </>
    )
}

export default Home;