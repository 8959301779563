import React,{useState, useEffect} from "react";
import axios from "axios";
import Carousel from 'react-bootstrap/Carousel';

const Slider = () =>{

const [data, setData]= useState([])

    useEffect(()=>{
        //category 11 is slider
        axios.get('https://asu.forward.ba/wp-json/wp/v2/posts?categories=11&_embed')
        .then((res)=>{
            setData(res.data)
        })
    },[])
    // console.log(data)
    return (
        <>
        <Carousel>
        {data.map( (el, key) =>{
      return <Carousel.Item interval={5000}>
        <img
          className="d-block w-100"
          src={el?._embedded['wp:featuredmedia'][0]?.media_details?.sizes?.full?.source_url}
          alt="First slide"
        />
        {/* <video class="img-fluid" autoplay loop muted>
        <source src="https://mdbcdn.b-cdn.net/img/video/Tropical.mp4" type="video/mp4" />
      </video> */}
        <Carousel.Caption>
          {/* <h1>{el.title.rendered}</h1> */}
          {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
         })}
      {/* <Carousel.Item interval={500}>
        <img
          className="d-block w-100"
          src="holder.js/800x400?text=Second slide&bg=282c34"
          alt="Second slide"
        />
        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="holder.js/800x400?text=Third slide&bg=20232a"
          alt="Third slide"
        />
        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item> */}
    </Carousel>



     
         
        </>
        
    )
}


export default Slider;