import React, { useState, useEffect } from "react";
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { FadeLoader } from "react-spinners"; 


const BazePodataka = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('selectedLanguage');

    useEffect(() => {
        axios.get("https://asu.forward.ba/wp-json/wp/v2/pages/581")
            .then((res) => {
                // console.log(res)
                setData(res.data);
                setLoading(false);
            })
            .catch(() => {
                alert('There was an error while retrieving the data')
            })
    }, [])

    // Proverite da li su podaci učitani pre nego što pristupite svojstvima
    
    // if (loading) {
    //     return <div>Loading...</div>;
    // }

    const naslov = storedLanguage === 'en' ? data?.title?.rendered : data?.title?.rendered;
    const opis = storedLanguage === 'en' ? data?.content?.rendered : data?.content?.rendered;

    
    return (
        <div className="container drugaSekcija dekanat">
            <div className="container dekanat">
                 {/* Prikazuje spinner dok se podaci učitavaju */}
                 {loading ? (
                        <div
                        className="text-center"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '70vh', // Postavite visinu na visinu ekrana (100% viewport visine)
                        }}
                      >
                        <FadeLoader color={"#123abc"} loading={loading} size={100} />
                      </div>
                    ) : (
                <div className="container drugaSekcija">
                    <h1>{parse(naslov)}</h1>
                    <div className="d-flex bd-highlight">
                        <div className="p-2 flex-fill bd-highlight">
                            {parse(opis)}
                        </div>
                    </div>
                </div>
                  )}
            </div>
        </div>
    );
}

export default BazePodataka;
