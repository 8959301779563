import React from 'react'


const Nastavnici = () =>{

  // const [nastavnici, setNastavnici] = useState([]);

  // useEffect(()=>{
  //     Axios.get("https://asu.forward.ba/wp-json/wp/v2/pages?categories=15&_embed")
  //     .then((res)=>{
  //       setNastavnici(res.data)
  //     })
  // },[])

  // console.log(nastavnici)

    return(
        <>
   <div class="container drugaSekcija dekanat nastavnik">
 <div class="container dekanat">
    <main role="main" class="container drugaSekcija">
            <div class="row">
              <div class="col-md-4">
                <img class="card-img-top nastanikSlika" src="./media/images/ermin_bravo.jpeg" alt="Card image cap"/>
              </div>
              <div class="col-md-8">
                <p>Ermin Bravo Ermin Bravo, rođen u Sarajevu (5. decembar 1979.), je bosanskohercegovački pozorišni i filmski glumac. 
                    Glumio je u mnogim predstavama: “Oluja”, “Noževi u kokoškama”, “Romeo i Julija”, “Helverova noć”, “Priviđenja iz srebrenog vijeka”, “Četvrta sestra”, “Košmar o Bosni”, “Život je san” te filmovima “Remake”, “Grbavica” i “Na putu”. Dobitnik je dva “Lovorova vijenca” za glumačke kreacije na medjunarodnom teatarskom festivalu MESS, kao i glumačke nagrade na Festivalu eksperimentalnih scena u Kairu i medjunarodnom Festivalu Glumca u Nikšiću. Filmske nagrade uključuju Nagradu za najboljeg glumca na Wine Country filmskom festivalu u San Franciscu za ulogu u filmu “Remake”, kao i Cinema for peace nagradu za ansambl filma “U zemlji krvi i meda” Angeline Jolie. Dobitnik je nagrade za najboljeg mladog glumca u 2005. godini, koju dodjeljuje Fondacija Mirna Filipan, i nagrade “Tmačin prsten” za najbolju mušku ulogu 2004. u predstavi “Helverova noć” Kamernog teatra 55 koju dodjeljuje TmačaArt.
                    Docent je za predmet Gluma na Akademiji scenskih umjetnosti u Sarajevu.</p><br/>
                    <p>Filmovi: ″Grain″ ~ Cemil (2017) | ″Muskarci ne plaču″ ~ Ahmed (2017) | „Dobra žena “~ Pop Brana (2016) | “Otok ljubavi″ ~ Grebo (2014) | “U zemlji krvi i meda” ~ Mehmet (2011) | “Prtljag” ~ Zaim (2011) | “Cirkus Kolumbija” ~ Fra Ante Gudelj (2010) | “Na putu” ~ Bahrija (2010) | “Grbavica” ~ Učitelj Muha (2005) | “Remake” ~ Tarik Karaga (2003) | ″Prvo smrtno iskustvo″ ~ Čistač (2001)</p><br/>
                    <p>Predstave: “Hipermnezija” | “Kako sam naucila da vozim” | “Kaligula” | “Fedrina ljubav” | “Helverova noć” | “Zvijer na mjesecu” | “Usamljeni zapad” | Dugo putovanje u noc” | “Zagrljenici” | “Pukovnik ptica” | “Povratak u pustinju” | “Oluja” | “Noževi u kokoškama” | “Romeo i Julija” | “Priviđenja iz
                    srebrenog vijeka” | “Četvrta sestra” | “Košmar o Bosni” | “Život je san” | “Lulu” | “Time out” | “Pukovnik ptica” | “Povratak u pustinju” | “Murlin Munro” | “Mjera za mjeru” | “Lisistrata” </p>
                {/* <p><a class="btn btn-secondary" href="#" role="button">View details »</a></p> */}
              </div>
              
            </div>
           <div class="row">
            <div class="col-md-4">
                <h1>Ermin Bravo</h1>
                <p>UNIVERZITET U SARAJEVU - AKADEMIJA SCENSKIH UMJETNOSTI</p>
                <p>PREDMET: GLUMA</p>
                <p>mail: ermin@asu.unsa.ba</p>
            </div>
            <div class="col-md-4">
               <p>RASPORED KONSULTACIJA</p> 
               <p> PONEDJELJAK 15:00 - 16:00</p>
               <p>UTORAK 19:00 - 20:00</p> 
               <p>SRIJEDA 19:00 - 20:00</p>
            </div>
            <div class="col-md-4">
                <div class="p-2 bd-highlight"> <img src="./media/images/ikone.png" class="img-fluid" alt="..."/>
             </div>

           </div>
    
            <hr/>  
            </div>                     
        </main>
        </div>
        </div>
        </>
    )
}

export default Nastavnici;