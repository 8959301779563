import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    lng: 'bs', // Default language
    resources: {
      en: {
        translation: {
          aboutus: 'About us',
          academicStaff: "Academic staff",
          home: 'Home',
          deanery: 'Deanery',
          departments: "Departments",
          teachers: 'Academic staff',
          documents: "Documents",
          international: "International",
          fullProfessors: "Full professors",
          assistantProfessors: "Assistant Professors",
          associateProfessors: "Associate Professors",
          assistants: "Assistants",
          seniorAssistants: "Senior Assistants",
          news: 'News',
          readMore: 'Read more',
          university: 'University of Sarajevo',
          academy: 'Academy of Performing Arts',
          course: 'Course',
          email: 'Email',
          consultationSchedule: 'Consultation Schedule',
        },
      },
      bs: {
        translation: {
          fullProfessors: "Redovni profesori",
          assistantProfessors: "Docenti",
          associateProfessors: "Vanredni profesori",
          assistants: "Asistenti",
          seniorAssistants: "Viši asistenti",
          academicStaff: "Akademsko osoblje",
          aboutus: 'O akademiji',
          home: 'Početna',
          deanery: 'Dekanat',
          departments: "Odsjeci",
          teachers: 'Akademsko osoblje',
          documents: "Propisi i akti",
          international: "Međunarodna saradnja",
          news: 'Novosti',
          readMore: 'Pročitaj više',
          university: 'UNIVERZITET U SARAJEVU',
          academy: 'AKADEMIJA SCENSKIH UMJETNOSTI',
          course: 'Predmet',
          email: 'Email',
          consultationSchedule: 'Raspored konsultacija',
        },
      },
      // Add more languages and translations as needed
    },
  });

export default i18n;
